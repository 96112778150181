import Vue from 'vue';
import VueRouter from 'vue-router';
import { setSession, getSession, initRoutes } from '@/utils/methods';
import { getUserInfo } from '@/api/login';
import baseRoutes from './baseRoutes';
import store from '@/store';
// import { getToken } from "@/utils/auth";

Vue.use(VueRouter);
// 消除路由重复点击报错
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err);
}

let routes = initRoutes(baseRoutes);
const createRouter = () =>
	new VueRouter({
		// mode: 'history',
		scrollBehavior: () => ({ y: 0 }),
		routes,
	});

const router = createRouter();

router.$clearRouter = () => {
	router.matcher = new VueRouter({ mode: 'history', routes }).matcher;
};
router.beforeEach((to, from, next) => {
	// 友盟统计
	if (_czc) {
		const referrerPath = to && to.fullPath ? to.fullPath : to.path;
		const referrerName = to && to.meta && to.meta.title ? to.meta.title : to.name;
		_czc.push(['_trackEvent', 'page_visit', referrerName, referrerPath]);
	}
	Vue.prototype.CancelApi.clear();
	// const token = getToken();
	const token = getSession('vue_admin_template_token');

	const { name, fullPath, hash, meta, params, path, query } = to;
	if (token) {
		if (from.path === '/login') {
			getUserInfo().then(res => {
				// console.log(res);
				store.commit('global/GET_USERINFO', res);
				setSession('userInfo',res);
			});
			next();
		}

		if (path !== '/login') {
			const obj = { name, fullPath, hash, meta, params, path };
			let newQuery = Object.assign({},query)
			if(newQuery && newQuery.refreshDetailData){
				delete newQuery.refreshDetailData
			}
			obj.query = newQuery
			store.dispatch('global/TAG_ADD', obj);
			next();
		} else {
			next('/home')
		}
	} else {
		if (to.path == '/login') {
			next();
		} else {
			next({ path: '/login', replace: true });
		}
	}
});
export default router;
